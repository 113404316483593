body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: Arial, sans-serif;
}

label {
  display: flex;
  align-items: center;
}

button {
  cursor: pointer;
}

button svg {
  font-size: 18px;
}

.label-bold {
  font-weight: bold;
}

.label-red {
  color: #ff0000;
}

.label-semi {
  font-size: small;
  color: #3a3a3a;
}

.label-mini {
  font-size: small;
}

.label-x-mini {
  font-size: x-small;
}

.layout {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.sidebar {
  width: 300px;
  background-color: #202123;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.sidebar h3 {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.sidebar button {
  width: 100%;
  text-align: left;
  padding: 10px 15px;
  margin: 5px 0;
  font-size: 16px;
  color: #d1d5db;
  background: none;
  border: none;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.sidebar button:hover {
  background-color: #2a2b32;
  color: #ffffff;
}

.sidebar button.active {
  background-color: #343541;
  color: #ffffff;
  font-weight: bold;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 18px;
}

.sidebar .extra-button {
  width: 100%;
  background-color: #d1d5db;
  color: #202123;
  border: none;
  border-radius: 8px;
  border: 1px solid #ddd;
  transition: all 0.3s ease;
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sidebar .extra-button:hover {
  background-color: #2a2b32;
}

.sidebar .extra-button svg {
  font-size: 18px;
}

.content {
  flex: 1;
  background-color: #f5f5f5;
  overflow-y: auto;
  height: 100% !important;
}

.content-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content h1 {
  font-size: 24px;
  padding: 20px;
  width: 100%;
  text-align: center;
}

.seperate-line {
  width: 100%;
  height: 2px;
  background-color: #d1d5db;
  margin: 10px 0;
}

.horizontal-layout {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-grow: 1;
  overflow: auto;
}

.horizontal-layout-column {
  flex: 1;
  height: 100%;
  padding-right: 10px;
  overflow: auto;
}

.vertical-layout {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  overflow: auto;
}

.vertical-layout::-webkit-scrollbar {
  display: none;
}

.right-layout {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin: 5px 0px;
}

.action-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.action-button:hover {
  background-color: #0060c7;
}

.edit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 3px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.edit-button:hover {
  background-color: #0060c7;
}

.delete-button {
  background-color: #ff0000;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 2px 5px;
  margin: 2px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.delete-button:hover {
  background-color: #dd0000;
}

.table-row-layer-1 {
  background-color: #888;
  padding: 5px 5px;
  font-weight: bold;
  border-bottom: 1px solid #000;
  border-radius: 5px 5px 0px 0px;
}

.table-row-layer-2 {
  background-color: #aaa;
  padding: 2px 5px;
  border-bottom: 1px solid #000;
}

.table-row-layer-3 {
  background-color: #bbb;
  padding: 2px 5px;
  border-bottom: 1px solid #000;
}

.table-row-layer-4 {
  background-color: #ddd;
  padding: 2px 5px;
  border-bottom: 1px solid #000;
}

.table-row-layer-5 {
  background-color: #fff;
  padding: 2px 5px;
  border-bottom: 1px solid #000;
}

.item-empty {
  text-align: center;
  padding: 5px;
  background-color: #ddd;
  color: #cc0000;
  font-size: 14px;
}

.statistic-item-container {
  display: flex;
}

.statistic-item {
  flex: 1;
  text-align: center;
  align-content: center;
  border-left: 1px solid #000;
}