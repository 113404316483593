.telesale-setting-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 2fr 2fr 2fr 1fr;
}

.project-management-grid {
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
}

.datasheet-management-grid {
  display: grid;
  grid-template-columns: 1fr 7fr 2fr;
}

.telesale-statistic-grid {
  display: grid;
  grid-template-columns: 3fr 1fr 22fr;
}