html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  margin: 10px;
}

.login-container h1 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  color: #333333;
}

.login-container form {
  width: 100%;
}

.login-container input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.login-container button {
  width: auto;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.login-container button:hover {
  background-color: #0056b3;
}

.login-container .error {
  color: #d9534f;
  margin-bottom: 15px;
  font-size: 14px;
}

.password-field {
  position: relative;
  display: flex;
  align-items: center;
}

.password-field input {
  width: 100%;
  padding-right: 30px;
}

.eye-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.login-container button {
  width: auto;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-container button:hover {
  background-color: #0056b3;
}