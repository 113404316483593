body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #f7f7f7;
  justify-content: center;
  align-items: flex-start;
}

.vertical-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}

.topbar {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #2c3e50;
  color: white;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  box-sizing: border-box;
}

.topbar-label {
  font-size: 18px;
  font-weight: bold;
}

.logout-button {
  background-color: #e74c3c;
  border: none;
  color: white;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #c0392b;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 60px);
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-x: hidden;
}

.section {
  width: 100%;
  max-width: 30%;
  padding: 30px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.section label {
  font-size: 32px;
  padding-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.button {
  width: calc(100% - 20px);
  padding: 15px 10px !important;
  border: none;
  border-radius: 5px;
  font-size: 24px !important;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-left: 10px;
  margin-right: 10px;
}

.button:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.button-blue {
  background-color: #3498db;
  color: white;
}

.button-blue:hover {
  background-color: #2980b9;
}

.button-red {
  background-color: #e74c3c;
  color: white;
}

.button-red:hover {
  background-color: #c0392b;
}

.button-green {
  background-color: #2ecc71;
  color: white;
}

.button-green:hover {
  background-color: #27ae60;
}

.button-yellow {
  background-color: #f39c12;
  color: white;
}

.button-yellow:hover {
  background-color: #e67e22;
}

.button-purple {
  background-color: #9b59b6;
  color: white;
}

.button-purple:hover {
  background-color: #8e44ad;
}

.section-2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.hidden {
  display: none;
}

@media (max-width: 768px) {
  .topbar-label {
    font-size: 16px;
  }

  .logout-button {
    font-size: 12px;
    padding: 6px 10px;
  }

  .section {
    max-width: 100%;
    padding: 15px;
  }

  .button {
    font-size: 14px;
    padding: 10px 15px;
  }
}
