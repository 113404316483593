/* Toàn bộ màn hình mờ khi modal hiện */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal container */
.modal {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    max-height: 60%;
    height: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: relative;
}

.modal-buttons-container {
    height: auto;
    flex: 1;
    overflow-y: auto;
}

/* Nút đóng (X) ở góc trên bên phải */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    color: #ec3f39;
    font-weight: bold;
    padding: 0;
}

.close-button:hover {
    color: #ff0000;
}

/* Input fields */
.modal input {
    width: 100%;  /* Đảm bảo chiều rộng chiếm toàn bộ container */
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
    position: relative; /* Để có thể đặt icon ở bên phải */
}

/* Nút xác nhận */
.modal-buttons button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    margin-top: 10px;
}

.modal-buttons button:hover {
    background-color: #0056b3;
}

/* Thông báo lỗi */
.error-message {
    color: #d9534f;
    font-size: 14px;
    margin-bottom: 10px;
}

/* Thông báo thành công */
.success-message {
    color: #5cb85c;
    font-size: 14px;
    margin-bottom: 10px;
}

.password-field {
    position: relative;
    display: flex;
    align-items: center;
}

.password-field input {
    width: 100%;
    padding-right: 30px; /* Để tạo khoảng trống cho biểu tượng mắt */
}

.eye-icon {
    position: absolute;
    right: 10px; /* Đặt khoảng cách từ bên phải */
    cursor: pointer;
}