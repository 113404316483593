.marketing-campaign-item {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.marketing-campaign-item-selected {
  border-radius: 5px;
  border: 1px solid #ff0000;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.marketing-campaign-item-empty {
  text-align: center;
  padding: 5px;
  background-color: rgb(202, 202, 202);
  border: 1px solid #ddd;
  color: #cc0000;
  font-size: 14px;
}

.marketing-customer-item {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 5px;
  margin-bottom: 5px;
}

.marketing-customer-item-selected {
  border-radius: 5px;
  border: 1px solid #ff0000;
  padding: 5px;
  margin-bottom: 5px;
}

.marketing-customer-item-title {
  font-weight: bold;
}

.marketing-customer-item-empty {
  text-align: center;
  padding: 5px;
  background-color: rgb(202, 202, 202);
  border: 1px solid #ddd;
  color: #cc0000;
  font-size: 14px;
}

.marketing-customer-log-item-sale {
  border-radius: 5px;
  background-color: #ccc;
  border: 1px solid #ddd;
  padding: 5px;
  margin-bottom: 5px;
}

.marketing-customer-log-item-system {
  padding: 5px;
  margin-bottom: 10px;
}

.marketing-customer-log-item-title {
  color: #ff0000;
  font-weight: bold;
}

.marketing-customer-log-item-logger {
  text-align: start;
  font-size: small;
  color: #000;
  font-weight: bold;
  margin-bottom: 5px;
}

.marketing-customer-log-item-message-sale {
  text-align: start;
}

.marketing-customer-log-item-message-system {
  text-align: center;
  color: #888;
  font-size: small;
  padding: 0px 50px;
}

.marketing-customer-log-item-datetime-sale {
  text-align: end;
  font-size: 12px;
  color: #555;
  margin-top: 10px;
}

.marketing-customer-log-item-datetime-system {
  text-align: center;
  font-size: 12px;
  color: #bbb;
  margin-top: 2px;
}

.marketing-customer-log-item-empty {
  text-align: center;
  padding: 5px;
  background-color: rgb(202, 202, 202);
  border: 1px solid #ddd;
  color: #cc0000;
  font-size: 14px;
}